
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.event {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
  @include themify($themes) {
    border-color: themed('primaryColor');
  }

  &__wrap {
    transition: .3s;
    height: 100%;
    width: 100%;
    z-index: 2;
    user-select: initial;
    position: initial;
    left: initial;
    top: initial;

    & :global .event__header {
      @include themify($themes) {
        background-color: themed('primaryColor');
        border-color: themed('primaryColor');
      }
    }

    & :global .event__header__title {
      @include themify($themes) {
        color: themed('editedBadgeTextColor');
        -webkit-text-fill-color: themed('editedBadgeTextColor');
      }

      &::placeholder {
        @include themify($themes) {
          color: themed('editedBadgeTextColor') !important;
        }
      }

      & [disabled] {
        @include themify($themes) {
          -webkit-text-fill-color: themed('editedBadgeTextColor');
        }
      }
    }
  }

  &__content {
    position: relative;
    flex: 1 0 auto;
    height: calc(100% - 120px);
    padding-bottom: 12px;
    box-sizing: border-box;
  }

  &__main {
    display: flex;
    height: calc(100% - 172px);

    &__loader {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  &__rows {
    flex: 0 0 auto;
  }

  &__row {
    display: flex;
    align-items: center;
    height: 40px;
    @include themify($themes) {
      border-bottom: 1px solid themed('editedBadgeBg');
      background-color: themed('workSpaceBg');
    }
    position: relative;
  }

  &__people {
    cursor: pointer;
  }

  &__schedule label {
    user-select: none;
    width: max-content;
  }

  &__field {
    width: 100%;

    :global .field {
      padding: 0 !important;
      border-bottom: none !important;
    }
  }

  &__field::placeholder {
    font-weight: normal;
    @include themify($themes) {
      color: themed('gray1') !important;
    }
  }

  &__field:global.error {
    & input::placeholder {
      @include themify($themes) {
        color: themed('red1') !important;
      }
    }
  }

  &__descr {
    height: 38px;
    max-height: 38px;
    overflow: auto;
    padding: 12px 16px 0 16px;
    @include word-break();
  }

  // TODO Find out if this chip is still used or not and refactor styles
  :global .event__descr__chip {
    &:hover {
      @include themify($themes) {
        border-color: themed('primaryColor');
        color: themed('primaryColor');
      }
    }

    margin-left: 8px;
    margin-right: 8px;
    display: inline-flex;
    padding: 0 7px;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    @include themify($themes) {
      border-color: themed('descrBorder');
    }
    border-width: 1px;
    border-style: solid;
    margin-bottom: 2px;
    height: 24px;
    border-radius: 4px;
    cursor: pointer;

    &__icon {
      width: 12px;
      display: flex;
      margin-right: 8px;

      svg {
        width: 12px;
        height: 12px;
      }

      path {
        @include themify($themes) {
          fill: themed('primaryColor');
        }
      }
    }

    &__label {
      max-width: 120px;
      margin-top: -1px;
      font-weight: 400;
      font-size: 14px;
      @include text-overflow();
    }
  }
}

.error {
  @include themify($themes) {
    border-color: themed('red1') !important;
  }
}

.loading {
  position: relative;
}

@include respond-to(mobile) {
  .event {
    border: none !important;
  }

  .event__wrap {
    min-height: 400px !important;
  }
}

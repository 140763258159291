
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
$collapse-transition: 0.3s;

.menu {
  display: grid;
  grid-template-rows: 80px 1fr 168px;
  height: 100%;
  width: 240px;
  overflow: hidden;
  transition: all $collapse-transition;

  @include themify($themes) {
    background-color: themed('workSpaceBg');
  }

  &.collapsed {
    width: 56px;

    .menu__header {
      justify-content: center;
      padding: 32px 0 28px !important;

      :global .logo {
        opacity: 0;
        visibility: hidden;
        transition: opacity $collapse-transition, visibility 0s $collapse-transition;
        overflow: hidden;
        width: 56px;
      }

      &__notify {
        position: absolute;
        top: 32px;
        left: 22px;
      }
    }

    .menu__content {
      padding: 0 4px 16px;
    }

    .menu__footer {
      justify-content: flex-start;
    }

    .menu__footer__arrow {
      i {
        transform: rotate(180deg);
      }
    }

    .menu__footer__profile {
      flex-direction: column-reverse;
      padding: 12px !important;

      & > div:first-child {
        width: 100%;
        padding: 0;
        margin-right: 0;
        margin-top: 8px;
        border: none !important;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 16px 28px;
    -webkit-app-region: drag;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    &__notify {
      display: flex;
      position: relative;
    }

    :global .logo {
      opacity: 1;
      transition: opacity $collapse-transition, visibility 0s;

      & img {
        width: 180px;
      }
    }
  }

  &__content {
    width: 100%;
    padding: 0 12px 16px;
    box-sizing: border-box;
    overflow-y: auto;
    @include hover-scroll-bar();
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;

    &__arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 3px 0;
      box-sizing: border-box;

      @include themify($themes) {
        border-top: 1px solid themed('gray50');
        border-bottom: 1px solid themed('gray50');
      }

      i {
        padding: 8px;
        cursor: pointer;
        transition: all $collapse-transition;
        transform: rotate(0deg);
      }
    }

    &__profile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 16px 12px 32px;
      box-sizing: border-box;

      @include themify($themes) {
        background-color: themed('mainMenuFooter');
      }

      & > div:first-child {
        width: calc(100% - 60px);
        padding-right: 12px;
        margin-right: 12px;

        @include themify($themes) {
          border-right: 1px solid themed('gray50');
        }
      }
    }
  }
}

@include respond-to(mobile) {
  .menu {
    grid-template-rows: 68px 1fr 128px !important;
    grid-template-columns: 240px;
  }

  .menu:not(.slideout) {
    display: none;
  }

  .menu__header {
    padding: 24px 16px !important;
  }

  .menu__footer__arrow {
    display: none;
  }
}

@include respond-to(tablet) {
  .menu {
    grid-template-rows: 68px 1fr 128px !important;
    grid-template-columns: 240px;
  }

  .menu:not(.slideout) {
    display: none;
  }

  .menu__footer__arrow {
    display: none;
  }
}


// MENU ITEM

.menuItem {
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }

  &.hidden {
    display: none;
  }

  &.collapsed {
    .menuItem__row {
      justify-content: center;
    }

    .menuItem__row.empty {
      margin-left: 0 !important;
    }

    .menuItem__row__icon.dropdown,
    .menuItem__row__label {
      display: none;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px 6px;
    border-radius: 3px;
    transition: .3s;
    user-select: none;
    @include text-overflow();

    @include themify($themes) {
      color: themed('mainMenuColor');
    }

    position: relative;

    &.empty {
      margin-left: 20px;
    }

    &.active,
    &:hover {
      @include themify($themes) {
        color: themed('primaryColor');
        background-color: themed('secondaryColor');
      }

      :global .fill {
        @include themify($themes) {
          fill: themed('primaryColor') !important;
        }
      }
    }

    & > a:first-child {
      margin-top: 0;
    }

    &__icon {
      path {
        transition: .3s;
      }

      &.dropdown {
        margin-right: 12px;

        &.close {
          transform: rotate(-90deg);
        }
      }
    }

    &__badge {
      position: absolute;
      left: 16px;
      top: 4px;
    }

    &__label {
      transition: initial !important;
      margin-left: 12px;
      @include text-overflow();
    }

    &__popup {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 152px;
      padding: 4px;
      box-sizing: border-box;
      gap: 4px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(57, 63, 70, 0.1);
      position: fixed;
      left: 48px;
      z-index: 9999 !important;

      @include themify($themes) {
        background-color: themed('mainBg');
        border: 1px solid themed('gray50');
      }

      .menuItem__row {
        display: flex;
        justify-content: flex-start !important;
      }

      .menuItem__row.active > .menuItem__row__label {
        @include themify($themes) {
          color: themed('primaryColor') !important;
        }
      }

      .menuItem__row__label {
        display: flex !important;
        margin-left: 2px !important;

        @include themify($themes) {
          color: themed('textColor');
        }
      }
    }
  }

  :global a.active :local .menuItem__row {
    @include themify($themes) {
      color: themed('primaryColor');
      background-color: themed('primary30Color');
    }

    :global .fill {
      @include themify($themes) {
        fill: themed('primaryColor') !important;
      }
    }
  }

  & > a:first-child .menuItem__row {
    margin-top: 0;
  }

}

.sub__menu {
  cursor: default;

  :global a.active :local .menuItem__row {
    user-select: none;
    outline: none;

    @include themify($themes) {
      color: themed('primaryColor');
      background-color: transparent;
    }
  }

  .menuItem__row {
    padding-left: 42px;
    line-height: 19px;
    user-select: none;
  }

  .menuItem__row:hover {
    @include themify($themes) {
      background-color: transparent;
    }
  }
}

@include respond-to(mobile) {
  .menuItem > a > div > span {
    font-weight: 600;
  }

  .menuItem__row__icon__dropdown {
    display: none !important;
  }

  .menuItem__row {
    outline: none !important;
    user-select: none;
  }

  .menuItem > a,
  .sub__menu > a {
    @include no-touch-select();
  }
}

@include respond-to(tablet) {
  .menuItem > a > div > span {
    font-weight: 600;
  }

  .menuItem__row__icon__dropdown {
    display: none !important;
  }

  .menuItem > a,
  .sub__menu > a {
    @include no-touch-select();
  }
}

a,
img {
  -webkit-user-drag: none !important;
}
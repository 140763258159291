
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.checkbox {
  margin-bottom: 0 !important;
  label {
    display: none;
  }
}

.selectionBox {
  position: relative;
  flex-grow: 0;
  border-radius: 4px;
  border: 1px solid transparent;
  margin-left: -1px;

  &.active {
    @include themify($themes) {
      border-color: themed('primaryColor');
    }
  }
}

.someSelection {
  position: absolute;
  left: 4px;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  pointer-events: none;
  @include themify($themes) {
    background-color: themed('primaryColor');
  }
  &:before {
    content: '-';
    position: absolute;
    left: 4px;
    top: -4px;
    font-size: 24px;
    @include themify($themes) {
      color: themed('mainBg');
    }
  }
}


        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.label {
  flex: 1 1 auto;
  @include line-clamp(2);
}

.avatar {
  flex-shrink: 0;
  @include themify($themes) {
    border: 2px solid themed('primaryColor');
  }
}

.badgeButton {
  cursor: pointer;
  &:hover {
    filter: brightness(1.1);
  }
}

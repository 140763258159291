
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.templates {
  height: fit-content;
  flex-wrap: wrap;
  overflow-y: auto;
}

.templateItem {
  width: 240px;
  height: 160px;
  cursor: pointer;
}

.activeTemplate {
  @include themify($themes) {
    border: 1px solid themed('primaryColor');
  }
}


        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.activeCategory {
  border-radius: 4px;
  @include themify($themes) {
    background-color: themed('primary60Color');
  }
}

.categoriesList {
  height: fit-content;
  flex: 1;
  overflow-y: auto;
}

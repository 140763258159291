
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.aa {
  box-sizing: border-box;
  z-index: 1;
  width: 100% !important;
  height: 100% !important;
  @include themify($themes) {
    border: 1px solid themed('mainBg');
  }

  & > span:empty {
    display: none;
  }

  &__highlighted {
    border-radius: 4px;

    &:hover {
      @include themify($themes) {
        background-color: themed('blueBg');
      }
    }
  }

  &__wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-sizing: border-box;
    flex-shrink: 0;

    &.micro {
      width: 24px;
      height: 24px;
    }

    &.small {
      width: 32px;
      height: 32px;
    }

    &.medium {
      width: 36px;
      height: 36px;
    }

    &.large {
      width: 40px;
      height: 40px;
    }

    &.xlarge {
      width: 56px;
      height: 56px;
    }

    &.xxlarge {
      width: 100px;
      height: 100px;
    }

    &.withGradientBorder {
      background: linear-gradient(
        90deg,
        #ff0000 5%,
        #ff8a00 16%,
        #ffe600 28%,
        #14ff00 39%,
        #00a3ff 49%,
        #0500ff 61%,
        #ad00ff 72%,
        #ff00c7 84%,
        #ff0000 95%
      );
      border: 1.5px solid transparent;
    }

    :global .statusIcon {
      position: absolute;
      top: -2px;
      right: -4px;
      z-index: 3;
      margin: 0 !important;
      @include themify($themes) {
        border: 1px solid themed('mainBg');
      }
    }
  }

  &__colors {
    z-index: 2;
    position: absolute;
    bottom: -2px;
    right: -4px;
    width: 14px;
    height: 14px;
    box-sizing: border-box;
  }

  &__flag {
    position: absolute;
    bottom: -2px;
    left: 0;
    border-width: 2px;
    box-sizing: border-box;
    margin: 0 !important;
    z-index: 2;
  }

  &__iconWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: none;

    &.iconLabel {
      margin-top: 8px;

      span {
        text-align: center;
        font-size: 10px;
        line-height: 14px;
        margin-top: 8px;
        max-width: 60px;
        @include themify($themes) {
          opacity: 0.35;
          color: #000;
        }
      }
    }
  }

  &__icon {
    display: flex;
    align-self: center;
    :global .fill {
      @include themify($themes) {
        fill: themed('primaryColor') !important;
      }
    }
  }

  &__icon.upload {
    max-width: 20px !important;
    max-height: 20px !important;

    svg {
      max-width: 20px !important;
      max-height: 20px !important;
    }

    :global .fill {
      opacity: 0.25;
      fill: #000 !important;
    }
  }

  &.accessDenied {
    @include themify($themes) {
      background-color: themed('gray50');
    }
  }

  :global i {
    margin: 0 !important;
  }
}


        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.customBarLayout {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  @include hide-scroll-bar();

  :global .react-grid-item {
    cursor: pointer;

    &.react-draggable {
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }

    &.react-draggable-dragging {
      z-index: 9;
    }
  }
}

.scriptButton {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: inherit !important;

  &:active {
    cursor: inherit !important;
  }
}

.scriptsListWrapper {
  width: 167px;
  min-height: 100px;
}

.scriptsList {
  max-height: 184px;
  min-height: 50px;
  overflow-y: auto;
}

.scriptItem {
  cursor: pointer;

  &:hover {
    @include themify($themes) {
      background-color: themed('workSpaceBg');
    }
  }
}


        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  height: 90%;
  max-height: 90%;
  width: 1000px !important;
  overflow-y: hidden !important;

  @include themify($themes) {
    background-color: themed('mainBg');
  }

  & > div {
    padding: 0;
  }
}

.content {
  height: 100%;
  padding: 0;
}


        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.ge {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 5;

  & > div:nth-child(2) {
    z-index: 1001 !important;
  }

  &.hide {
    opacity: 0;
  }

  & :global .expand-collapse-canvas {
    z-index: 9 !important;
  }

  :global .popper[data-popper-placement] {
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    border-radius: 50%;
    cursor: pointer;
    z-index: 9999;
    @include themify($themes) {
      background-color: themed('primaryColor');
    }

    &:hover {
      @include themify($themes) {
        background-color: themed('primaryColorHover');
      }
    }
  }

  :global .popper-handle {
    box-sizing: border-box;
    border-radius: 50%;
    cursor: pointer;
    z-index: 9999;
    background-image: url('./img/plus.svg');
  }

  :global .popper-linked-actor {
    box-sizing: border-box;
    border-radius: 50%;
    cursor: pointer;
    z-index: 9999;
    opacity: 1;
    white-space: nowrap;

    @include themify($themes) {
      border: 1px solid themed('gray35');
    }

    & > span {
      margin-left: 16px;
    }

    &.faded {
      opacity: 0.4;
    }
  }

  :global .cxtmenu-item {
    :global .fill {
      @include themify($themes) {
        fill: themed('mainBg') !important;
      }
    }
  }

  :global .popper-tooltip {
    z-index: 9999;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    word-break: break-word;
    min-width: 80px;
    min-height: 20px;
    padding: 0 8px;
    @include themify($themes) {
      background-color: themed('textColor');
      color: themed('editedBadgeTextColor');
    }

    span {
      word-wrap:break-word;
    }

    &[data-popper-placement] :before {
      content: '';
      position: absolute;
      border: 6px solid transparent;
    }

    &[data-popper-placement^="top"] :before {
      bottom: -12px;
      @include themify($themes) {
        border-top: 6px solid themed('textColor');
      }
    }

    &[data-popper-placement="top-start"] :before {
      left: 12px;
    }

    &[data-popper-placement="top"] :before {
      right: calc(50% - 6px);
    }

    &[data-popper-placement="top-end"] :before {
      right: 12px;
    }

    &[data-popper-placement^="bottom"] :before {
      top: -12px;
      @include themify($themes) {
        border-bottom: 6px solid themed('textColor');
      }
    }

    &[data-popper-placement="bottom-start"] :before {
      left: 12px;
    }

    &[data-popper-placement="bottom"] :before {
      right: calc(50% - 6px);
    }

    &[data-popper-placement="bottom-end"] :before {
      right: 12px;
    }

    &[data-popper-placement^="left"] :before {
      top: 4px;
      right: -12px;
      @include themify($themes) {
        border-left: 6px solid themed('textColor');
      }
    }

    &[data-popper-placement^="right"] :before {
      top: 4px;
      left: -12px;
      @include themify($themes) {
        border-right: 6px solid themed('textColor');
      }
    }
  }
}


        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.bg {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 10000000;
  overflow: auto;
  position: fixed;
  transition: opacity 450ms cubic-bezier(.23, 1, .32, 1) 0ms;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: rgba(57, 63, 70, 0.7);
}

.callScreen {
  min-width: 300px;
  max-width: 400px;
}

.btnCall {
  @include themify($themes) {
    background-color: themed('reactionDoneColor') !important;
  }
}


        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.attachTable {
  margin: 12px 0;
  padding-bottom: 40px;

  @include themify($themes) {
    border-top: 1px solid themed('workSpaceBorder');
  }

  hr {
    margin: 0;
  }
}

.attachHeaderRow,
.attachUploadingRow,
.attachRow {
  padding: 0 !important;

  :global .scTableCell {
    padding: 0 !important;
  }
}

.previewImg {
  max-width: 32px;
  max-height: 32px;
}

.imgWrap {
  @include img_wrap_icon(32px, 'editedBadgeBg');
}

.fileWrap {
  @include img_wrap_icon(32px, 'descrBorder');
}

@include respond-to(mobile) {
  .attachRow {

    :global .scTableCell:nth-child(4),
    :global .scTableCell:nth-child(5) {
      display: none;
    }
  }
}

.renameTextField {
  width: 100%;
}

.attachUploadingRowCell {
  margin-right: 0px;
  width: 100% !important;
}
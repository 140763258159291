
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.titleWrap {
  position: relative;
  border-radius: 3px;
  border: 1px solid transparent;
  font-weight: 600;
  min-width: 0;
  line-height: 24px;
  padding: 4px 24px 4px 4px;
  overflow: hidden;
  @include word-break();

  &.primary {
    box-sizing: border-box;
  }

  &.secondary {
    font-weight: normal;
    @include themify($themes) {
      color: themed('gray2');
    }

    &:hover {
      @include themify($themes) {
        border-color: themed('disabled');
        background-color: themed('primary50Color');
      }
    }
  }

  &.editable {
    cursor: pointer;

    &:hover {
      @include themify($themes) {
        border-color: themed('disabled');
        background-color: themed('primary50Color');
      }

      i {
        opacity: 1;
      }
    }
  }

  i {
    position: absolute;
    top: 10px;
    right: 8px;
    opacity: 0;
  }
}

.title {
  box-sizing: border-box;
  min-width: 0;
  @include line-clamp();

  &.isNew {
    font-weight: 400 !important;
    @include themify($themes) {
      color: themed('gray1') !important;
    }
  }
}

.editField textarea {
  line-height: 22px;
}

.readOnly {
  @include themify($themes) {
    color: themed('mainBg');
  }
}


        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.title {
  flex: 1;
}

.label {
  line-height: 24px;
}

.editable {
  max-width: calc(100% - 16px);
  cursor: pointer;
  border-radius: 4px;
  width: fit-content;
  border: 1px solid transparent;

  i {
    opacity: 0;
  }

  &:hover {
    @include themify($themes) {
      border-color: themed('disabled');
      background-color: themed('primary50Color');
    }

    i {
      opacity: 1;
    }
  }
}

.editIcon {
  align-self: center;
}

.editField {
  max-width: calc(100% - 16px);
  border-radius: 4px;

  @include themify($themes) {
    border: 1px solid themed('primaryColor');
    background-color: themed('mainBg');
  }
}

.textInput {
  width: 100%;

  & > div {
    border-bottom-style: none;
    padding: 0;
  }

  input {
    font-size: 18px !important;
    font-weight: 600;
  }
}


        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.slider {

  :global .rc-slider-dot:not(.rc-slider-dot-active) {
    @include themify($themes) {
      border-color: themed('primary30Color');
    }
  }

  :global .rc-slider-dot-active {
    @include themify($themes) {
      border-color: themed('primaryColor');
    }
  }

  :global .rc-slider-handle {
    opacity: 1;
    @include themify($themes) {
      border-color: themed('primaryColor');
    }
  }

  :global .rc-slider-rail {
    @include themify($themes) {
      background-color: themed('primary30Color');
    }
  }

  :global .rc-slider-track {
    @include themify($themes) {
      background-color: themed('primaryColor');
    }
  }
}


        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.modal {
  height: 90% !important;
  max-height: 90% !important;
}

.content {
  padding: 0 !important;
  height: 100%;
  overflow: hidden;
}

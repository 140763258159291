
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.history {
  height: 100%;
  overflow: hidden;

  &__select {
    width: fit-content;
    min-width: 76px;
    border-radius: 4px;
    @include themify($themes) {
      border: 1px solid themed('blueBg');
    }

    :global .field {
      @include themify($themes) {
        background-color: themed('blueBg');
        border-color: themed('blueBg') !important;
      }

      input {
        font-weight: 600;
        @include themify($themes) {
          color: themed('primaryColor') !important;
        }
      }
    }

    &__item:not(:first-child) {
      @include themify($themes) {
        border-top: 1px solid themed('gray50');
      }
    }
  }
}

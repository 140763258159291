
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.settings {
  padding: 0 200px;

  &__info {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    cursor: pointer;
  }

  &__picture {
    width: 120px;
    cursor: pointer;
    margin-right: 20px;

    &__upload {
      span {
        display: none;
      }
      & > div {
        border-radius: 50%;
        @include themify($themes) {
          border: 1px solid themed('descrBorder');
        }
      }

      &.loaded {
        i {
          z-index: 0;
        }
      }
    }
  }

  &__public {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > div:nth-child(1) {
      flex: 1 1 auto;
    }

    & > div:nth-child(2) {
      flex: 1 0 120px;
      margin-left: 20px;
    }
  }

  &__credentials {
    display: flex;
    flex-wrap: wrap;

    & > div {
      width: calc(50% - 10px);
      box-sizing: border-box;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        width: calc((100% - 40px) / 3);
        margin-right: 20px;
      }

      &:nth-child(3) {
        margin-right: 0;
      }

      &:nth-child(4) {
        margin-left: 0;
        margin-right: 20px;
      }
    }
  }
}

.row {
  display: flex;
  margin: 8px 0;
  position: relative;

  &__divider {
    margin-bottom: 12px;
  }

  &__label {
    margin: 8px 0;
    position: relative;
  }

  &__field {
    :global .field {
      @include themify($themes) {
        background-color: themed('mainBg');
      }
    }

    &:not(.focus) {
      :global .label {
        font-weight: 600;
        @include themify($themes) {
          color: themed('textColor');
        }
      }
    }
  }
}

.scriptTitle {
  flex: 1 1 auto;
}

.shortName {
  flex: 1 0 120px;
  padding-top: 0;
}

.embeddedSettings {
  padding: 0;
}

.embeddedField {
  flex: 1 1 auto;
}

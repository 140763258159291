
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.dividerVertical {
  height: 100% !important;
  width: 1px;
}

.sections {
  height: calc(100% - 52px);
}

.categoriesSection {
  width: 20%;
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.templateSection {
  @include themify($themes) {
    background-color: themed('workSpaceBg');
  }
}

.templatesList {
  flex: 1;
  overflow-y: auto;
}

.loaderBlock {
  position: absolute;
  top: 0;
  left: 0;
}

.loaderLabel {
  position: absolute;
  bottom: 16px;
  right: 16px;
}


        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.loader {
  margin-top: 16px;
  overflow: hidden;
}

.list {
  overflow: auto;
}

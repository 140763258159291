
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.contextMenuItem {
  min-width: 200px;
  position: relative;
  cursor: pointer;

  &.medium {
    padding: 4px 12px;
    height: 36px;
  }

  &.small {
    padding: 2px 12px;
    height: 28px;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.hidden {
    display: none;
  }

  &:hover {
    @include themify($themes) {
      background-color: themed('primary60Color');
    }
  }
}

.arrow {
  transform: rotate(-90deg);
}

.nested {
  position: absolute;
  top: 0;
  left: 220px;
  padding: 0 4px;
  height: 32px;
}


        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.edit {
  margin-bottom: 4px;
}

.JSONEdit_border {
  margin-bottom: 20px;
}
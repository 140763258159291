
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.streams {
  padding: 0 24px;
  box-sizing: border-box;
  margin-bottom: 20px;
  height: fit-content;
  @include themify($themes) {
    border-bottom: 1px solid themed('gray50');
  }

  &__tabs {
    display: flex;
    align-items: center;
    flex-shrink: 1;
    width: 100%;
    height: 36px;
    box-sizing: border-box;

    & > div:first-child {
      height: 36px;
      overflow-y: visible;
    }

    ::-webkit-scrollbar {
      height: 4px;
    }

    &__wrap {
      display: flex;
      align-items: center;
      width: 100%;
      overflow: hidden;
    }

    &__item {
      margin: 0 4px !important;
      padding: 8px 0 8px 12px !important;
      min-width: auto !important;
      position: relative;

      & span {
        @include text-overflow();
      }

      & > span:only-child {
        margin-right: 12px !important;
      }

      &__extra {
        display: flex;
        align-items: center;
        & > *:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }

  &__controls {
    flex-shrink: 0;
    margin-top: -4px;
    padding-left: 12px;
    @include themify($themes) {
      border-left: 1px solid themed('gray50');
    }

    & > * {
      position: relative;
      cursor: pointer;
      i {
        @include btn-icon-hover();
      }
    }
  }
}

.draggable {
  position: relative;
  display: flex;
  width: 100% !important;
  height: 36px !important;
  flex-wrap: nowrap;
  overflow: hidden;
  &::-webkit-scrollbar {
    height: 2px;
  }

  &__item {
    display: flex;
    align-items: center;
    position: relative;
    @include themify($themes) {
      background-color: themed('mainBg');
    }

    &__handle {
      align-self: center;
      cursor: grab;
      padding: 4px;
      position: absolute;
      left: -4px;
      top: 5px;
      z-index: 3;
      opacity: 0;
    }

    &:hover {
      .draggable__item__handle {
        opacity: 1;
      }
    }

    &__wrap {
      width: 100%;
      height: 100%;
      z-index: 1;

      .streams__tabs__item {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        justify-content: flex-start;
        position: absolute;
        margin: 0 !important;
        padding: 8px 4px 8px 16px !important;

        span:first-child {
          flex-grow: 1;
          text-align: start;
        }
      }
    }
  }

  &:hover {
    overflow-x: auto;
  }
}

.ghost {
  @include ghost;
}

.dragging {
  cursor: grabbing;
  .draggable__item__handle {
    opacity: 1;
  }
}

.renameField {
  z-index: 4;
  @include themify($themes) {
    background-color: themed('mainBg');
  }
}

@include respond-to(mobile) {
  .streams {
    padding: 0 12px;
  }
}


        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.account {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 200px;

  &__balance {
    width: 40%;
    @include text-overflow();
    @include themify($themes) {
      color: themed('gray2');
    }

    &__tooltip {
      z-index: 9999 !important;

      div {
        display: flex;
        flex-direction: column;
        span {
          width: fit-content;
          max-width: 360px;
          @include text-overflow();
        }
      }
    }
  }
}

.select {
  width: 100%;
  @include modal-select;

  & :global .label {
    font-size: 14px;
    margin-top: -24px;
  }

  &.showBalance {
    width: 60%;
  }
}

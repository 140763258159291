
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.attaches {
  display: flex;
  flex-direction: column;

  &__files {
    flex: 1 0 200px;
    padding: 0px 16px 4px 16px;
    max-height: 170px;
    overflow-y: auto;

    & > div {
      margin-top: 8px;
      padding-bottom: 0px;
    }
  }

  &__general {
    display: flex;
    padding: 5px 16px 5px 16px;

    &__icon {
      @include img_wrap_icon(32px, 'descrBorder');
    }

    &__autorun {
      display: flex;
      align-items: center;
      margin-top: 4px;

      span {
        margin-left: 8px;

        @include themify($themes) {
          color: themed('gray2');
        }
      }
    }
  }
}

.table {
  width: 100%;

  &__head {
    height: 0;
  }

  :global .scTableCell:nth-child(2) {
    padding: 0 12px !important;
  }

  &__row {
    padding: 0 !important;
    cursor: pointer;

    @include themify($themes) {
      background-color: themed('mainBg');
    }

    &:hover {
      @include themify($themes) {
        background-color: themed('primary50Color');
      }
    }

    :global .scTableRowList {
      padding: 0 !important;
    }

    &__icon:hover {
      :global .fill {
        @include themify($themes) {
          fill: themed('primaryColor');
        }
      }
    }

    & hr {
      margin: 0 !important;
      display: block !important;
    }
  }

  &__cell {
    @include text-overflow();

    &.title > span {
      @include text-overflow();
    }
  }

  &__menu {
    position: absolute;
    right: 20px;
    bottom: 0;
    z-index: 12;

    &__wrap {
      position: relative;
    }
  }

  hr {
    display: none;
  }
}

@include respond-to(mobile) {
  .attaches {
    width: 100% !important;
  }

  .table__row {

    :global .scTableCell:nth-child(3),
    :global .scTableCell:nth-child(4) {
      display: none;
    }
  }
}
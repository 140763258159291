
        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.chart {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
  width: 100%;
  height: 100%;
}

.pinButton {
  width: 24px;
  height: 24px !important;

  &:not(.pinned) {
    border: none;
    @include themify($themes) {
      background-color: themed('mainBg') !important;
    }

    :global .fill {
      @include themify($themes) {
        fill: themed('gray2') !important;
      }
    }
  }
}

.pinned {
  @include themify($themes) {
    background-color: themed('primaryColor');
  }
}


        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.se {
  width: 100%;
  position: relative;

  &__field {
    box-sizing: border-box;

    &.default {
      :global .field {
        @include themify($themes) {
          background-color: themed('mainBg');
        }
      }

      :global .searchList {
        max-height: 252px !important;
      }
    }

    &.modal {
      @include modal-textfield;
    }

    &.template {
      :global .searchList {
        max-height: 378px !important;
      }
    }

    &.showCreateBtn {
      :global .field > div:first-child {
        width: calc(100% - 60px);
      }
    }
  }

  &__title {
    margin: 16px;
  }

  &__item {
    position: relative;
    padding: 0 16px !important;

    & > div {
      height: 44px !important;
      min-width: 1px;

      & > div {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        & > div {
          width: auto;
          margin-top: 0 !important;
        }
      }
    }

    &.showId {
      justify-content: space-between;

      &:after {
        content: 'ID: ' attr(name);
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        background: #e8f1fd;
        border-radius: 4px;
        color: #393f48;
        padding: 0 4px;
        flex-shrink: 0;
        flex-grow: 1;
        @include text-overflow();
      }

      &.showBreadCrumbs:after {
        margin-top: -12px;
      }
    }

    &.showDetails {
      span:first-child {
        margin-top: -16px !important;
      }
    }

    &.showBreadCrumbs > div {
      align-items: flex-start;

      & > div {
        margin-top: 6px;
      }
    }

    &__avatar {
      margin-right: 12px;
    }

    &__badge {
      margin-right: 12px;
    }

    &__uat {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      height: 24px;
      overflow: hidden;

      i {
        margin: 0 4px;
        transform: rotate(180deg);
      }

      & > span {
        @include text-overflow();

        &:nth-child(2):last-child {
          max-width: 50%;
        }

        &:only-of-type {
          max-width: 100% !important;
        }

        &:not(:only-of-type) {
          max-width: 33%;
        }
      }

      &__chip {
        height: auto !important;
        margin-left: 4px;
        padding: 0 2px !important;
        border: none !important;
        @include themify($themes) {
          background-color: themed('gray50') !important;
        }
        span {
          font-size: 10px !important;
        }
      }
    }

    &__type {
      position: absolute !important;
      bottom: 0;
      left: 16px;
      margin: 0 !important;
    }

    &__details {
      max-width: calc(100% - 70px);
      position: absolute;
      bottom: 4px;
      left: 54px;
      @include text-overflow();
      @include themify($themes) {
        color: themed('gray1');
      }
    }
  }

  &__chip {
    position: relative;

    &.extra > span {
      margin-left: 8px;
    }

    &__badge {
      position: absolute;
      left: 8px;
    }
  }

  &__wrap {
    box-sizing: border-box;
    width: 312px;
    box-shadow: 0 3px 8px rgba(57, 63, 72, 0.06);
    border-radius: 4px;
    @include themify($themes) {
      background-color: themed('mainBg');
      border: 1px solid themed('gray50');
    }

    div [class*=')-field-'] {
      padding-bottom: 2px !important;
      padding-top: 4px !important;
    }

    & :global .popoverContent {
      position: initial;
      margin-top: 0 !important;
      padding: 0 !important;
      width: 100% !important;
    }

    & :global .searchList {
      border: 0;
      box-shadow: initial;
      padding-top: 0;
    }

    & :global .field {
      margin: 0 16px 16px;
    }

    & :global [name='not_found'] {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}

/**
 * To remove this workaround SelectEntityField should have
 * specified width, what requires deeper refactoring
 */
.endAdorment {
  position: absolute;
  right: 0;
}

.createBtn {
  width: 60px !important;
  height: 34px !important;
}

.autocompleteContentShort {
  :global .searchList {
    max-height: 160px !important;
  }
}

.autocompleteContentHeight {
  :global .searchList {
    max-height: 216px !important;
  }
}

.account {
  :global .field {
    height: 42px;
  }
}


        @import "@control-front-end/common/styles/variables";
        @import "@control-front-end/common/styles/mixins";
        
.noAccess {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &__item {
      flex-direction: row-reverse;
      margin: 0 !important;
      gap: 4px;
      @include themify($themes) {
        background-color: themed('gray50') !important;
        border-color: themed('gray50') !important;
      }

      :global .badge {
        border-width: 1px;
      }
    }
  }
}
